import moment from "moment/moment";
import jwt_decode from "jwt-decode";
// import CryptoJS from "crypto-js";

export const returnValue = (v, type) => {
  if (v && v != "" && v != undefined && v != null) {
    switch (type) {
      case "Date":
        return new Date(v) != "Invalid Date" ? new Date(v) : "";
      case "Phone":
        return v?.replace(/[^0-9\+\.]/g, "");
      case "Number":
        return parseInt(v);
      case "Alphabet":
      case "AlphaNumaric":
      case "NumberString":
        v?.toString();
      default:
        return v;
    }
  }
  return "";
};

export const returningValue = (v, type) => {
  if (v && v != "" && v != undefined && v != null && v!="null" && v!="undefined") {
    switch (type) {
      case "Date":
        return new Date(v) != "Invalid Date" ? moment(v).format("MM-DD-YYYY") : "";
      case "Phone":
        return v?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      case "Number":
        return parseInt(v);
      case "Alphabet":
      case "AlphaNumaric":
      case "NumberString":
        v?.toString();
      default:
        return v;
    }
  }
  return "";
};

export const returnErrorColor = (condition) => {
  let v = condition;
  return v && v != undefined && v != null && v != "" ? "border border-danger" : "";
};

export const getUserfromSS = () => {
  return JSON.parse(sessionStorage.getItem("user") || "{}");
};

export const logOutFunction = () => {
  sessionStorage.clear();
  window.location.pathname = "/";
};

export const jwtDecodedData = () => {
  if (sessionStorage.getItem("token") && sessionStorage.getItem("token") != "") {
    let res = jwt_decode(sessionStorage.getItem("token"));
    return res;
  }
  return {};
};

export const checkLoginStatus = () => {
  let authenticated = false;
  let token = jwtDecodedData();
  if (token?.exp > Math.floor(Date.now() / 1000)) {
    if (Object.keys(getUserfromSS())?.length > 0) {
      if (getUserfromSS()?.status == "ACTIVE") {
        authenticated = true;
      }
    } else {
      // logOutFunction();
      // notify(false, "Please Login");
    }
  } else {
    // logOutFunction();
    // notify(false, "Your session expired Please Login");
  }
  return authenticated;
};

export const multiSelectToString = (data, keys) => {
  let temp = {};
  keys?.map((e) => {
    if (Array.isArray(data[e]) && data[e]?.length > 0) {
      temp[e] = data[e]?.map((v) => v?.value)?.toString();
    } else {
      temp[e] = "";
    }
  });
  return temp;
};

// export const checkedFn = (data, value) => {
//   return data && data == value ? true : false;
// };

// export const reconvertingPhonenumberinArray = (dataArray, array) => {
//   dataArray?.map((data, i) => {
//     array?.map((e, j) => {
//       if (data[e] && data[e] != "" && data[e].length != 0) {
//         data[e] = data[e]?.toString()?.replace(/[^0-9]/g, "");
//       }
//     });
//   });
// };

// export const getUniqueByKey = (array, key) => {
//   return [...new Map(array.map((item) => [item[key], item])).values()] || [];
// };

// export const sortingList = (array, key) => {
//   return array.sort((a, b) => (a?.[key] > b?.[key] ? 1 : a?.[key] < b?.[key] ? -1 : 0));
// };

// export const encryptData = (data) => {
//   let en = CryptoJS.AES.encrypt(data?.toString(), "COLINA")?.toString();
//   let encoded = CryptoJS.enc.Base64.parse(en).toString(CryptoJS.enc.Hex);
//   return encoded;
// };

// export const decryptData = (encoded) => {
//   let temp = {};
//   Object.entries(encoded)?.map(([key, value], i) => {
//     if (i > 0) {
//       let decoded1 = CryptoJS.enc.Hex.parse(value).toString(
//         CryptoJS.enc.Base64
//       );
//       let decrypted1 = CryptoJS.AES.decrypt(decoded1, "COLINA").toString(
//         CryptoJS.enc.Utf8
//       );
//       temp[key] = decrypted1;
//     }
//   });
//   return temp;
// };

// export const removeDuplicates = (list, key) => {
//   let result = [];
//   list.filter((ele) => {
//     if (result.map((element) => element?.[key] == ele?.[key])?.length == 0) {
//       result.push(ele);
//     }
//   });
//   return result;
// };

// export const formatter = new Intl.NumberFormat("en-IN", {
//   style: "currency",
//   currency: "INR",
//   // These options are needed to round to whole numbers if that's what you want.
//   //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
//   //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
// });

import { getUserfromSS } from "../ConfigFiles/SmallFunctions";

const initalState = {
  filterOptions: {},
};
const reducer = (state = initalState, action) => {
  switch (action.type) {
    case "CHANGEFILTER":
      return {
        ...state,
        filterOptions: { ...state.filterOptions, ...action.payload },
      };
    default:
      return state;
  }
};

export { reducer };

import React from "react";
import { useNavigate } from "react-router-dom";
import { css } from "../ConfigFiles/Consts";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="d-flex  justify-content-center align-items-center h-100 gap-2 " style={{ backgroundColor: css.themeColor }}>
      <div className="d-flex   justify-content-center  gap-4 vinay">
        <div className="pointer" onClick={() => navigate('/user/dashboard')}>
          Home
        </div>
        <div className="pointer" onClick={() => navigate("/terms")}>
          Terms
        </div>
        <div className="pointer" onClick={() => navigate("/privacy")}>
          Privacy
        </div>
        <div className="pointer" onClick={() => navigate("/contact")}>
          Contact Us
        </div>
      </div>

      <div className=" text-black  d-flex justify-content-center sai  " style={{ color: css.textColor2 }}>
        © {new Date().getFullYear()} All rights reserved | Maintained by&nbsp;
        <a target="_blank" href={process.env.REACT_APP_HOME_URL} className="text">
          {" "}
          Takshika
        </a>
      </div>
    </div>
  );
};

export default Footer;

import instance from "../Services";
import Swal from "sweetalert2";

export const notify = (status, msg) => {
  Swal.fire({
    position: "center",
    icon: status == true ? "success" : "error",
    title: msg,
    // text:msg,
    showConfirmButton: false,
    timer: 4000,
  });
};


export const notifyWithOk = (status, msg) => {
  Swal.fire({
    position: "center",
    icon: status == true ? "success" : "error",
    title: msg
  });
};

export const dilogueOkay = (status, title, message) => {
  Swal.fire({
    title: message,
    text: title,
    icon: status == true ? "success" : "error",
    confirmButtonText: 'Okay'
  });
}
export const dilogueBox = (text, onClickFn) => {
  Swal.fire({
    title: text,
    showCancelButton: true,
    confirmButtonText: 'Yes',
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      onClickFn()
    }
  })
}

export const getList = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    if (res?.data?.status == true && res?.data?.data?.length != 0) {
      if (res.data.data?.length == undefined) {
        return [];
      } else {
        return res.data.data;
      }
    } else {
      return [];
    }
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getfList = async (url) => {
  try {
    let res = await instance.get(url);
    if (res?.data?.status == true && res?.data?.data?.length != 0) {
      return res.data.data;
    } else {
      return [];
    }
  } catch (e) {
    console.log(e);
    return [];
  }
};


export const saveWithOk = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    notifyWithOk(res?.data?.status, res?.data?.message);
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
    console.log(e);
    return {};
  }
};


export const save = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    if (res?.data?.status) {
      notify(res?.data?.status, res?.data?.message);
    } else {
      dilogueOkay(res?.data?.status, "", res?.data?.message)
    }
    return res;
  } catch (e) {
    console.log(e);
    return {};
  }
};




export const savewithoutMessage = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    if (res?.data?.status == true) {
      return res;
    } else {
      notify(res?.data?.status, res?.data?.message);
      return {};
    }
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const getById = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    if (res?.data?.status == true && res?.data?.data?.length != 0) {
      return res.data.data;
    } else {
      return {};
    }
  } catch (e) {
    console.log(e);
    return {};
  }
};



export const getUserProfile = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    if (res?.data?.status == true && res?.data?.data?.length != 0) {
      return res.data.data;
    } else {
      return {};
    }
  } catch (e) {
    console.log(e);
    return {};
  }
};
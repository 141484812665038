import { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./SharedComponents/Footer";
import Header from "./SharedComponents/Header";
import { css } from "./ConfigFiles/Consts";
import TakshikaPrivateRoute from "./PrivateRoute/TakshikaPrivateRoute";
import { notify } from "./ConfigFiles/SharedFunctions";
import ActivateUser from "./Pages/Auth/ActivateUser";
import { checkLoginStatus, logOutFunction } from "./ConfigFiles/SmallFunctions";

// import Button from ""
const Auth = lazy(() => import("./Pages/Auth/Auth"));
const TermsAndConditions = lazy(() => import("./Pages/StaticPages/TermsAndConditions"));
const PrivacyPolicies = lazy(() => import("./Pages/StaticPages/PrivacyPolicies"));
const ContactUs = lazy(() => import("./Pages/StaticPages/ContactUs"));
const User = lazy(() => import("./Pages/User/UserRC"));
const Dashboard = lazy(() => import("./Pages/User/Dashboard"));

const ResetPassword = lazy(() => import("./Pages/Auth/ResetPassword"));

const App = () => {

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {

    // Add event listener to check session when browser tab becomes active
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (!checkLoginStatus())
          logOutFunction();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  return (
    <>
      <Suspense fallback={() => <div>Loading ....</div>}>
        <Router>
          <div style={{ height: "10vh", position: "fixed", top: "0px", backgroundColor: css.themeColor, zIndex: "999" }} className=" w-100">
            <Header />
          </div>
          <div style={{ minHeight: "84vh", marginTop: "10vh" }} className="">
            <Routes>
              <Route path="/" element={<Auth />} />
              <Route path="terms" element={<TermsAndConditions />} />
              <Route path="contact" element={<ContactUs />} />
              <Route path="privacy" element={<PrivacyPolicies />} />
              <Route
                path="user/*"
                element={
                  <TakshikaPrivateRoute>
                    <User />
                  </TakshikaPrivateRoute>
                }
              />
              <Route path="resetpassword/:id" element={<ResetPassword />} />
              <Route path="/user/activateUser/:encodedId" element={<ActivateUser />} />
            </Routes>
          </div>
          <div style={{ height: "6vh", position: "fixed", bottom: "0px", zIndex: "999" }} className=" bg bg-white w-100 ">
            {/* <div style={{ height: "10vh", }} className=" bg bg-white w-100"> */}
            <Footer />
          </div>
        </Router>
      </Suspense>
    </>
  );
};

export default App;

import React, { useEffect } from "react";
import { save } from "../../ConfigFiles/SharedFunctions";
import { urls } from "../../ConfigFiles/Consts";
import { useNavigate, useParams } from "react-router-dom";

const ActivateUser = () => {
  const { encodedId } = useParams();
  const navigate = useNavigate();
  const activateUser = async () => {
    let res = await save(urls.user.activateUser, { encodedId: encodedId });
    //console.log(res,"sddghadghjfghjsd",encodedId)
    if (res?.data?.status == true) {
    navigate("/");
    }
  };
  
  useEffect(() => {
    activateUser();
  }, []);

  return <div></div>;
};

export default ActivateUser;

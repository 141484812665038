import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Logo from "../Images/Takshika Iyengar Matrimony1.png";
import { css } from "../ConfigFiles/Consts";
import { AiOutlineHome } from "react-icons/ai";
import { Link } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { FiMail } from "react-icons/fi";
import { FiLogOut } from "react-icons/fi";
import { RiAdminLine } from "react-icons/ri";
import "../App.css"
import { checkLoginStatus, getUserfromSS, logOutFunction } from "../ConfigFiles/SmallFunctions";

const Header = () => {
  const [isMobile, setMobile] = useState(false)
  const navigate = useNavigate();

  const openModel = (e) => {
    setMobile(!isMobile)
  }
  //console.log("rhjfujf")
  return (
    <div className="d-flex justify-content-between align-items-center h-100 p-2">
      <div>
        <a target="_blank" href={process.env.REACT_APP_HOME_URL}>
          <img src={Logo} width="200" height="60" alt="" className="imageMobileSize" />
        </a>
      </div>


      <div className="d-flex">

        <div>
          <div className="d-flex gap-2" >
            <AiOutlineHome onClick={() => navigate("/user/dashboard")} size={css.iconSize1} color={css.textColor} className="pointer" title="Home" />
            {process.env.REACT_APP_SUPER_ADMIN.split(",").includes(JSON.parse(sessionStorage.getItem("user"))?.email) ?<RiAdminLine onClick={() => navigate("/user/admin")} size={css.iconSize1} color={css.textColor} className="pointer" title="Admin" />:""}
            {checkLoginStatus() ?
              <div className="d-flex gap-2">
                <CgProfile onClick={() => navigate("/user/profile")} size={css.iconSize1} color={css.textColor} className="pointer" title="Profile" />
                <FiLogOut onClick={logOutFunction} size={css.iconSize1} color={css.textColor} className="pointer" title="Log out" />
              </div>
              : <>
                <FiMail onClick={() => navigate("/contact")} size={css.iconSize1} color={css.textColor} className="pointer" />
              </>}
          </div>
          {/* {checkLoginStatus() && <div>{getUserfromSS()?.name}</div>} */}

        </div>
      </div>


    </div>

  );
};

export default Header;

export const css = {
  themeColor: "#cde5fa",
  themeColor2: "#8cc7fa",

  themeColor3: "#006F93",
  // themeColor2: "#cde5fa",

  textColor2: "#ffff",
  textColor: "#000",
  iconSize1: "1.5rem",
  iconSize2: "1.1rem",
  fontSize: 25,
  fontWeight: "bold",
};

export const baseURL = process.env.REACT_APP_API_URL;
export const basefileURL = process.env.REACT_APP_API_ASSETS_URL;

export const urls = {
  auth: {
    login: "login/signin",
    forgotPassword: "login/forgotPassword",
    resendVerificationLink:"user/resendVerification",
    resetPassword: "login/resetPassword",
  },
  user: {
    create: "user/create",
    getList: "listing/getById",
    getProfile: "profile/getById",
    save: "profile/save",
    activateUser: "User/activateUser",
    upload:"profile/uploads",
    deleteImages:"profile/deleteImages",
    profileUpload:"profile/avatar",
    horoscopeUpload:"profile/horoscope",
    getImages:"profile/getImages"
  },
  info: {
    contactUs: "contactUs/save"
  },
  master: {
    gotra: "Listing/getGhotraListing",
    caste: "listing/getCasteList",
    ageGroup: "listing/getAgeGroupList",
    ageDiff: "listing/getAgeDifference",
    countryList: "Countries/getAllCountries",
    statesList: "Countries/getAllStates",
    mothersCaste: "listing/getMotherCasteList",
    residingCountry: "Countries/getResidentialCountries",
    getStatesByCountryId: "Countries/getAllStatesByCountryId",
    getCitysByStateId: "Countries/getAllCitiesByStateId",
    getAllCities: "Countries/getAllCities",
    getAllCitiesByState: "Countries/getAllCitiesByStateId",
    getAllVisas: "Visas/getAll",
    uploadProfile: "Profile/avatar",
    uploadImages: "Profile/photos",
  },
  profile: {
    manageProfile: "profile/manageVisibleFields",
    updateStatus: "profile/updateProfileStatus",
    getUserProfile: "profile/getUserProfile",
    getAllProfiles: "profile/getAllProfiles"
  }
};
